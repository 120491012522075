/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import {useAnimations, useGLTF} from "@react-three/drei";
import * as THREE from "three";

export default function NFT(props) {
    const group = React.useRef();
    const { nodes, materials, animations } = useGLTF(
        "/Qvrse_Coin6_metallic_anim.gltf"
    );
    const { actions } = useAnimations(animations, group);
    const audio = new Audio("/coin_sound.mp3");

    const playAnimation = () => {
        actions.ArmatureAction.reset().play();
        audio.volume = 0.01;
        audio.play();
        actions.ArmatureAction.loop = THREE.LoopOnce;
    }

    return (
        <group ref={group} {...props}   onClick={()=>playAnimation()} dispose={null}>
            <group name="Scene">
                <group name="ARM_Coin" position={[0, 0.0051, -0.0841]} scale={1.8958}>
                    <skinnedMesh
                        name="Coin"
                        geometry={nodes.Coin.geometry}
                        material={materials.Material}
                        skeleton={nodes.Coin.skeleton}
                    />
                    <primitive object={nodes.Bone} />
                </group>
            </group>
        </group>
    );
}


useGLTF.preload("/Qvrse_Coin6_metallic_anim.gltf");