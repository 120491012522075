import { Canvas } from '@react-three/fiber';
import React from "react";
import { OrbitControls, Stage, PerspectiveCamera, Loader} from "@react-three/drei";
import { Suspense } from "react";
import NFT from "./models/NFT";
import './App.css';


function App() {
    const ref = React.useRef()

    return (
      <div className="App">
          <Canvas dpr={[1, 2]} camera={{ fov: 50 }} style={{ background: "rgb(13 15 31)" }}>
              <ambientLight />
              <pointLight position={[100, 40, 0]}  />
              <pointLight position={[100, 50, 40]}  />
              <Suspense fallback={null}>
                  <Stage controls={ref} preset="portrait" intensity={2}  shadows={false} environment="apartment">
                      false
                      <NFT />
                      false
                  </Stage>
              </Suspense>

              <PerspectiveCamera
                  makeDefault
                  position={[0, 0, 500]}
                  zoom={0.6}
              />
              <OrbitControls enablePan={false} autoRotate />
          </Canvas>
          <Loader />
      </div>
  );
}

export default App;
